/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.6.11/dist/vue.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/moment@2.25.3/moment.min.js
 * - /npm/vuetify@2.3.0/dist/vuetify.min.js
 * - /npm/vue-the-mask@0.11.1/dist/vue-the-mask.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
